import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Screen, Heading } from '../../Screen'
import { MetaMaskSetup } from '../MetaMaskSetup'
import { PurchaseLand } from '../PurchaseLand'
import { Text, View } from '../..'
import { PurchaseLandHistory } from '../PurchaseLandHistory'
import { useSelector } from '../../../state/store'

export function LandSaleScreen(): JSX.Element {
  const { connectedAccounts } = useSelector((state) => ({
    connectedAccounts: state.metaMask.connectedAccounts,
  }))
  const [currentEthBalance, setCurrentEthBalance] = React.useState('')
  const ethAddress = connectedAccounts[0]

  return (
    <Screen testID="screen-admin">
      <Heading>Land Sale</Heading>
      <View style={styles.landImage}>
        <Text>PLACEHOLDER IMAGE FOR MULTIVERSE METAVERSE LAND</Text>
      </View>
      <MetaMaskSetup
        setCurrentEthBalance={setCurrentEthBalance}
        style={styles.metamaskSetup}
      />
      <PurchaseLand ethAddress={ethAddress} ethBalance={currentEthBalance} />
      <PurchaseLandHistory address={ethAddress} />
    </Screen>
  )
}

const styles = StyleSheet.create({
  landImage: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 20,
    justifyContent: 'center',
    marginBottom: 20,
    minHeight: 300,
  },
  metamaskSetup: {
    marginBottom: 40,
  },
})
