/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import Clipboard from 'expo-clipboard'
import { balance } from '@mv/api/lib/src/types'
import { StakingBonusLevel } from '@mv/api/lib/src/schema/accounts'
import { Text, View } from '..'
import { useThemeColors } from '../../constants/colors'
import { useSelector } from '../../state/store'
import { humanizeBalance } from '../../helpers'
import { Button } from '../Button'
import { MyWalletBalanceLocked } from './MyWalletBalanceLocked'
import { useTheme } from '../../hooks/useTheme'

export function MyWalletBalance(): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const { medium, wallet } = useThemeColors()
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  const noteStyle =
    theme === 'hadron'
      ? {
          color: medium,
        }
      : undefined

  if (!(user.user?.balances?.AI && user.user?.account)) {
    return null
  }

  const balanceAI = user.user.balances.AI

  const balanceColorStyle = {
    color: wallet.balanceColor,
  }

  return (
    <View style={styles.container}>
      <View style={[styles.balanceView]}>
        <Text
          preset="paragraphXLarge"
          weight={theme === 'hadron' ? 'semiBold' : 'bold'}
          style={[styles.balance, balanceColorStyle]}
        >
          Current total balance:{' '}
          {humanizeBalance(balance.total(balanceAI._), 2)}
          &nbsp;AI
        </Text>
        <Text preset="paragraphXSmall" style={[styles.note, noteStyle]}>
          Balance does not reflect any pending incoming transactions
        </Text>
        <StakingBonus stakingBonusLevel={user.user.account.stakingBonusLevel} />
      </View>
      <MyWalletBalanceLocked />
      <Button
        onPress={async () => {
          Clipboard.setString(user.user?.account?.address || '')
        }}
      >
        <View style={[styles.addressView]}>
          <Text weight="semiBold" style={styles.balance}>
            Multiverse Wallet address:{' '}
          </Text>
          <Text numberOfLines={1}>
            {user.user.account.address || 'Address not available'} ⧉
          </Text>
        </View>
      </Button>
    </View>
  )
}
const themedStyles = {
  hadron: StyleSheet.create({
    addressView: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 20,
    },
    balance: {
      fontSize: 18,
    },
    balanceView: {
      marginBottom: 20,
    },
    container: {},
    note: {
      fontSize: 12,
    },
    stakingBonus: {
      fontSize: 18,
      fontStyle: 'italic',
    },
  }),
  multiverse2022: StyleSheet.create({
    addressView: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 24,
    },
    balance: {},
    balanceView: {
      marginBottom: 24,
    },
    container: {
      marginBottom: 40 - 24,
    },
    note: {},
    stakingBonus: {
      fontStyle: 'italic',
      marginTop: 10,
    },
  }),
}

type StakingBonusProps = {
  stakingBonusLevel?: StakingBonusLevel
}
function StakingBonus({
  stakingBonusLevel,
}: StakingBonusProps): JSX.Element | null {
  const { wallet } = useThemeColors()
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  let stakingBonusColor: typeof wallet.stakingBonus
  let displayText: string
  switch (stakingBonusLevel) {
    case 'diamond':
      displayText = 'Maximum Staking Bonus Unlocked!'
      stakingBonusColor = wallet.stakingBonusHighlight
      break
    default:
      displayText = 'Staking Bonus: To Be Determined'
      stakingBonusColor = wallet.stakingBonus
  }
  const stakingBonusColorStyle = {
    color: stakingBonusColor,
  }
  return (
    <Text
      preset="paragraphXLarge"
      weight={theme === 'hadron' ? 'semiBold' : 'bold'}
      style={[styles.stakingBonus, stakingBonusColorStyle]}
    >
      {displayText}
    </Text>
  )
}
