/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UserProfileVisible } from '@mv/api/lib/src/schema/accounts/userProfile'
import {
  ModalStringData,
  initialState,
  ModalsState,
  GenericModalData,
} from './modalsTypes'
import { MetaMask } from './metaMaskTypes'
import { userActions } from './userSlice'
import { RootState } from './state'

const thunks = {
  showEditProfileModal: createAsyncThunk<UserProfileVisible | undefined, void>(
    'modals/showEditProfileModal',
    async (_args, { dispatch, getState }) => {
      let userState = (getState() as RootState).user
      if (!userState.user) {
        dispatch(modalsSlice.actions.hideModal())
        return undefined
      }
      await dispatch(userActions.loadUserProfile())
      userState = (getState() as RootState).user
      return userState.user?.profile
    }
  ),
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: initialState(),
  reducers: {
    showLoginModal(state, action: PayloadAction<ModalStringData | undefined>) {
      state.displayModal = 'login'
      state.data = action.payload || null
    },
    showCreateAccountModal(
      state,
      action: PayloadAction<ModalStringData | undefined>
    ) {
      state.displayModal = 'createAccount'
      state.data = action.payload || null
    },
    showForgotPasswordModal(
      state,
      action: PayloadAction<ModalStringData | undefined>
    ) {
      state.displayModal = 'forgotPassword'
      state.data = action.payload || null
    },
    showChangePasswordModal(
      state,
      action: PayloadAction<ModalStringData | undefined>
    ) {
      state.displayModal = 'changePassword'
      state.data = action.payload || null
    },
    showWithdrawalModal(state) {
      state.displayModal = 'withdrawal'
      state.data = null
    },
    showMetaMaskTransferModal(
      state,
      action: PayloadAction<{
        maxAmount: string
        selectedAddress: string
        userAddress: string
        metaMask: MetaMask
      }>
    ) {
      state.displayModal = 'metaMaskTransfer'
      state.data = action.payload
    },
    showMetaMaskTransferSuccessModal(state, action: PayloadAction<string>) {
      state.displayModal = 'transactionSuccess'
      state.data = {
        heading: 'Deposit From MetaMask',
        transactionType: 'deposit',
        transactionHash: action.payload,
      }
    },
    showTransactionSuccessModal(
      state,
      action: PayloadAction<{
        heading?: string
        transactionType?: string
        transactionHash?: string
      }>
    ) {
      state.displayModal = 'transactionSuccess'
      state.data = {
        heading: action.payload.heading,
        transactionType: action.payload.transactionType,
        transactionHash: action.payload.transactionHash,
      }
    },
    showTotpSetupModal(state) {
      state.displayModal = 'totpSetup'
      state.data = null
    },
    showGenericModal(state, action: PayloadAction<GenericModalData>) {
      state.displayModal = 'generic'
      state.data = action.payload
    },
    updateModalData: {
      prepare: (data: Partial<ModalsState['data']>) => ({ payload: data }),
      reducer: (state, action: PayloadAction<Partial<ModalsState['data']>>) => {
        if (state.data) {
          Object.assign(state.data, action.payload)
        }
      },
    },
    hideModal(state) {
      state.displayModal = null
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.showEditProfileModal.fulfilled, (state, action) => {
      state.displayModal = 'editProfile'
      state.data = action.payload || null
    })
  },
})

export const modalsActions = {
  ...modalsSlice.actions,
  ...thunks,
}
