import { ReactNode } from 'react'
import { UserProfileVisible } from '@mv/api/lib/src/schema/accounts/userProfile'
import { MetaMask } from './metaMaskTypes'

export type ModalType =
  | 'login'
  | 'createAccount'
  | 'forgotPassword'
  | 'generic'
  | 'editProfile'
  | 'changePassword'
  | 'withdrawal'
  | 'metaMaskTransfer'
  | 'metaMaskTransferSuccess'
  | 'transactionSuccess'
  | 'totpSetup'
export type GenericModalData = {
  title?: string
  messages?: ReactNode[]
  addCloseLink?: boolean
}
export type ModalStringData = {
  [key: string]: string | undefined
}
export type MetaMaskTransferData = {
  maxAmount: string
  selectedAddress: string
  userAddress: string
  metaMask: MetaMask
}
export type TransactionSuccessData = {
  heading?: string
  transactionType?: string
  transactionHash?: string
}

type ModalsNullState = {
  displayModal: null
  data: null
}
type ModalsNullDataState = {
  displayModal: 'totpSetup'
  data: null
}
type ModalsStringDataState = {
  displayModal:
    | 'login'
    | 'createAccount'
    | 'forgotPassword'
    | 'changePassword'
    | 'withdrawal'
  data: ModalStringData | null
}
type ModalsMetaMaskTransferState = {
  displayModal: 'metaMaskTransfer'
  data: MetaMaskTransferData
}
type ModalsTransactionSuccessState = {
  displayModal: 'transactionSuccess'
  data: TransactionSuccessData
}
type ModalsEditProfileState = {
  displayModal: 'editProfile'
  data: UserProfileVisible
}
type ModalsGenericState = {
  displayModal: 'generic'
  data: GenericModalData
}
export type ModalsState =
  | ModalsNullState
  | ModalsNullDataState
  | ModalsStringDataState
  | ModalsMetaMaskTransferState
  | ModalsTransactionSuccessState
  | ModalsEditProfileState
  | ModalsGenericState

export function initialState(): ModalsState {
  return {
    displayModal: null,
    data: null,
  }
}
