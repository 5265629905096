import * as React from 'react'
import { StyleSheet } from 'react-native'
import Constants from 'expo-constants'
import { event } from '@mv/api/lib/src/schema/contracts'
import { Section, View, Text } from '..'
import {
  db,
  collection,
  CollectionReference,
  query,
  where,
  onSnapshot,
} from '../../firebase/firestore'
import { LinkButton } from '../LinkButton'
import { DefaultBackgroundColorsTable } from '../table'
import { shortAddress } from '../../helpers'

const config = Constants.manifest.extra?.config

type PurchaseRowEntry = {
  key: string
  landType: string
  quantity: string
  blockTimestamp: Date
  status: string
  transactionHash: React.ReactNode
}

type PurchaseHistoryItem = {
  eventId: string
  landType: string
  quantity: string
  transactionHash: string
  blockTimestamp: Date
  status: event.Status
}

type Props = {
  /** Ethereum address to display land purchase history for */
  address: string | undefined
}
export function PurchaseLandHistory({ address }: Props): JSX.Element | null {
  const isMounted = React.useRef(true)

  const [purchases, setPurchases] = React.useState<
    null | PurchaseHistoryItem[]
  >(null)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (!address) {
      setPurchases([])
    } else {
      setPurchases(null)
      const eventCollectionRef = collection(
        db,
        event.getCollectionPath('Multiverse Land Sale')
      ) as CollectionReference<event.Event>
      const eventQuery = query(
        eventCollectionRef,
        where(event.name, '==', 'Mint'),
        where('args.sender', '==', address)
      )
      return onSnapshot(eventQuery, (snap) => {
        if (isMounted.current) {
          setPurchases(
            snap.docs
              .map((doc) => {
                const evnt = doc.data()
                return {
                  eventId: doc.id,
                  landType: `${evnt.args.landSize}x${evnt.args.landSize}`,
                  quantity: evnt.args.quantity,
                  transactionHash: evnt.transactionHash,
                  blockNumber: evnt.blockNumber,
                  blockTimestamp: evnt.blockTimestamp.toDate(),
                  status: evnt.status,
                }
              })
              .sort((a, b) => a.blockNumber - b.blockNumber)
          )
        }
      })
    }
    return () => {}
  }, [address])

  if (purchases === null || purchases.length === 0) {
    return null
  }

  const tableData = extractTableDataFromLandPurchaseDocs(purchases)

  return (
    <Section heading="Purchase history" containerStyle={styles.container}>
      <Text>Recent transactions may take a few minutes to appear</Text>
      <View style={styles.table}>
        <DefaultBackgroundColorsTable
          data={tableData}
          columns={[
            'landType',
            'quantity',
            'blockTimestamp',
            'status',
            'transactionHash',
          ]}
          columnOptions={{
            landType: {
              name: 'Size',
            },
            quantity: {
              name: 'Quantity',
            },
            blockTimestamp: { name: 'Time' },
            status: { name: 'Status' },
            transactionHash: { name: 'Transaction Hash' },
          }}
          rowStyle={styles.row}
          rowVerticalPadding={20}
          rowHorizontalPadding={20}
          spaceBetweenColumns={40}
          cellTextStyle={styles.entryText}
        />
      </View>
    </Section>
  )
}
const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  entryText: {
    fontSize: 14,
  },
  row: {},
  table: {
    flex: 1,
  },
})

function extractTableDataFromLandPurchaseDocs(
  landPurchaseDocs: PurchaseHistoryItem[]
) {
  const tableData: PurchaseRowEntry[] = []
  landPurchaseDocs.forEach((purchase) => {
    tableData.push({
      key: `${purchase.eventId}`,
      landType: purchase.landType,
      quantity: purchase.quantity,
      blockTimestamp: purchase.blockTimestamp,
      status: purchase.status
        ? purchase.status[0].toUpperCase() +
          purchase.status.slice(1).toLowerCase()
        : '',
      transactionHash: (
        <LinkButton
          labelStyle={styles.entryText}
          url={`https://${config.etherscanHostname}/tx/${purchase.transactionHash}`}
          label={shortAddress(purchase.transactionHash, 8, 8, false)}
        />
      ),
    })
  })
  return tableData
}
