import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { ethers } from 'ethers'
import { requests } from '@mv/api'
import { metaverse, token } from '@mv/api/lib/src/types'
import { View, Text } from '..'
import * as Form from '../form'
import { useSelector, useDispatch } from '../../state/store'
import { logger } from '../../logger'
import { logEvent, analytics } from '../../firebase/analytics'
import { makeCallableFunction, FunctionsError } from '../../firebase/functions'
import { modalsActions } from '../../state/modalsSlice'
import { humanizeBalance, shortAddress } from '../../helpers'
import { ERROR_OOPS } from '../../constants/messages'
import { Loading } from '../Loading'

type LandType = metaverse.LandType
interface MetaMaskError extends Error {
  code: unknown
}

// TODO: Update with correct land sizes, prices, and eth function names for buying them
const LandTypes: LandType[] = ['1x1', '2x2', '3x3']
type LandPrices = Record<LandType, token.Units>

type Props = {
  ethAddress: string | undefined
  ethBalance: string
}

export function PurchaseLand({ ethAddress, ethBalance }: Props): JSX.Element {
  const [continueOnMetaMask, setContinueOnMetaMask] = React.useState(false)
  const [landPrices, setLandPrices] = React.useState<LandPrices | undefined>(
    undefined
  )
  const [selectedLandType, setSelectedLandType] =
    React.useState<LandType | null>(null)
  const [selectedQuantity, setSelectedQuantity] = React.useState<number | null>(
    null
  )

  React.useEffect(() => {
    if (ethAddress) {
      fetchLandSalePrices(ethAddress)
        .then((response) => {
          setLandPrices(response?.prices)
        })
        .catch((e) => {
          setLandPrices(undefined)
          logger.error('Unable to fetch land sale prices', e)
        })
    }
  }, [ethAddress])

  const weiBalance = token.decimalValueToUnits(ethBalance)

  const ethereumAccountDisplay = (
    <Form.Item>
      <Form.Label>Ethereum Account</Form.Label>
      <Text>
        {ethAddress
          ? shortAddress(ethAddress, 5, 5, false)
          : 'Select a account'}
      </Text>
    </Form.Item>
  )

  if (!landPrices) {
    return (
      <View style={styles.container}>
        {ethereumAccountDisplay}
        <Loading />
      </View>
    )
  }

  const renderLandTypeButtonArray = LandTypes.map((landType, index) => {
    const landPrice = landPrices[landType]
    const disabled =
      token.compare(weiBalance, landPrice) !== 1 || continueOnMetaMask
    const prettyLandPrice = humanizeBalance(landPrice, 2)
    return (
      <SelectButton
        key={`type-${landType}`}
        style={index === 0 && styles.buttonFirst}
        name={landType}
        cost={prettyLandPrice}
        onPress={() => {
          setSelectedLandType(landType)
          setSelectedQuantity(null)
        }}
        disabled={disabled}
        fade={!!selectedLandType && selectedLandType !== landType}
      />
    )
  })

  const renderQuantityButtonArray = []
  for (let i = 1; i <= 10; i += 1) {
    let disabled = true
    let prettyPrice: string | undefined
    if (selectedLandType) {
      const landPrice = landPrices[selectedLandType]
      const quantityCost = token.perform(
        token.Operation.Multiply,
        landPrice,
        i.toString()
      )
      prettyPrice = humanizeBalance(quantityCost, 2)
      disabled = token.compare(weiBalance, quantityCost) !== 1
    }
    disabled = disabled || continueOnMetaMask
    renderQuantityButtonArray.push(
      <SelectButton
        key={`quantity-${i}`}
        style={i === 1 && styles.buttonFirst}
        name={`${i}`}
        cost={prettyPrice}
        onPress={() => {
          setSelectedQuantity(i)
        }}
        disabled={disabled}
        fade={!!selectedQuantity && selectedQuantity !== i}
      />
    )
  }

  return (
    <View style={styles.container}>
      {ethereumAccountDisplay}
      <Form.Item>
        <Form.Label>Dimension of land to purchase:</Form.Label>
        <View style={styles.buttonContainer}>{renderLandTypeButtonArray}</View>
      </Form.Item>
      <Form.Item>
        <Form.Label>
          Number{selectedLandType ? ` of ${selectedLandType} ` : ' '}to
          purchase:
        </Form.Label>
        <View style={styles.buttonContainer}>{renderQuantityButtonArray}</View>
      </Form.Item>
      <PurchaseSubmitButton
        landType={selectedLandType}
        quantity={selectedQuantity}
        landPrices={landPrices}
        ethAddress={ethAddress}
        continueOnMetaMask={setContinueOnMetaMask}
      />
      {continueOnMetaMask && (
        <Text style={styles.continueText}>
          Please continue the purchase on MetaMask
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 600,
  },
  buttonFade: {
    opacity: 0.5,
  },
  buttonFirst: {
    marginLeft: 0,
  },
  buttonInside: {
    minWidth: 108,
  },
  buttonOutside: {
    marginBottom: 10,
    marginRight: 10,
  },
  container: {},
  continueText: {
    marginBottom: 16,
    marginTop: 8,
  },
  submitButtonInside: {
    minWidth: 250,
  },
})

function SelectButton({
  name,
  cost,
  disabled,
  fade,
  style,
  onPress,
}: {
  name: string
  cost?: string
  disabled?: boolean
  fade?: boolean
  style?: StyleProp<ViewStyle>
  onPress: () => void
}): JSX.Element {
  return (
    <Form.SubmitButton
      style={[styles.buttonInside, !disabled && fade && styles.buttonFade]}
      containerStyle={[styles.buttonOutside, style]}
      label={name}
      sublabel={cost && `${cost} ETH`}
      onPress={async () => {
        onPress()
        return false
      }}
      disabled={disabled}
    />
  )
}

type FormError = {
  generic?: string
}

function PurchaseSubmitButton({
  landType,
  quantity,
  landPrices,
  ethAddress,
  continueOnMetaMask,
  disabled = false,
}: {
  landType: LandType | null
  quantity: number | null
  landPrices: LandPrices
  ethAddress?: string
  continueOnMetaMask: (continueOn: boolean) => void
  disabled?: boolean
}): JSX.Element {
  const { metaMask } = useSelector((state) => ({
    metaMask: state.metaMask.metaMask,
  }))
  const dispatch = useDispatch()

  const [formError, setFormError] = React.useState({} as FormError)

  const buttonDisabled = disabled || !ethAddress || !landType || !quantity

  let totalAmount: string | undefined
  let label = 'Purchase'
  if (landType && quantity) {
    totalAmount = `${humanizeBalance(
      token.perform(
        token.Operation.Multiply,
        landPrices[landType],
        quantity.toString()
      ),
      2
    )} ETH`
    label = `Purchase ${quantity} ${landType}`
  }

  return (
    <>
      <View style={styles.buttonContainer}>
        <Form.SubmitButton
          style={styles.submitButtonInside}
          label={label}
          sublabel={totalAmount}
          onPress={async () => {
            setFormError({})
            if (ethAddress && landType && quantity) {
              const contractAddressResponse =
                await fetchLandSaleContractAddress(ethAddress).catch((_e) => {
                  const e = _e as FunctionsError
                  if (e.code === 'internal') {
                    logger.error('Failed to retrieve contract address', e)
                    setFormError({
                      generic: ERROR_OOPS,
                    })
                  } else if (e.code === 'permission-denied') {
                    logger.warn(
                      'Permission to retrieve contract address was denied'
                    )
                    setFormError({
                      generic:
                        'Sorry, the sale has not started yet or you do not have permission to participate in the sale.',
                    })
                  } else {
                    logger.error(
                      'Unexpected error received retrieving contract address',
                      e
                    )
                    setFormError({ generic: ERROR_OOPS })
                  }
                  return null
                })
              if (metaMask && ethAddress && contractAddressResponse) {
                // TODO: Add a balance check on the contract to see if the user can
                // purchase more (contract will block, but the user experience will be
                // better if the app provides an error message before the metamask steps)
                try {
                  const { contractAddress, contractABI, functionName } =
                    contractAddressResponse

                  const txn = await constructPurchaseTransaction(
                    contractAddress,
                    contractABI,
                    ethAddress,
                    functionName,
                    landType,
                    quantity,
                    landPrices
                  ).catch((e) => {
                    logger.error(
                      'Unexpected error received when constructing purchase transaction',
                      e
                    )
                    setFormError({ generic: ERROR_OOPS })
                  })
                  if (!txn) {
                    return false
                  }

                  continueOnMetaMask(true)
                  const txnHash = (await metaMask.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [txn],
                  })) as string
                  logger.info(`Txn hash: ${txnHash}`)
                  logEvent(analytics(), `buyLand_${functionName}`, {
                    hash: txnHash,
                  })
                  return () => {
                    continueOnMetaMask(false)
                    dispatch(
                      modalsActions.showTransactionSuccessModal({
                        heading: 'Land Purchase',
                        transactionType: 'land purchase',
                        transactionHash: txnHash,
                      })
                    )
                  }
                } catch (_e) {
                  const e = _e as MetaMaskError
                  switch (e.code) {
                    case 4001:
                      logger.warn(e)
                      // user cancelled or rejected
                      setFormError({
                        generic: 'Transaction cancelled on MetaMask.',
                      })
                      break
                    default:
                      // show an oops?
                      logger.error(e)
                      setFormError({
                        generic:
                          'Something went wrong on MetaMask. Please try again later.',
                      })
                  }
                }
              }
            } else if (!ethAddress) {
              setFormError({
                generic:
                  'Unable to acquire your account address from MetaMask. Please refresh the page and try again.',
              })
            } else if (!landType) {
              setFormError({
                generic: 'Please select the type of land you wish to purchase.',
              })
            } else if (!quantity) {
              setFormError({
                generic: 'Please select the number to purchase.',
              })
            }

            continueOnMetaMask(false)
            return false
          }}
          disabled={buttonDisabled}
        />
      </View>
      <Form.Error error={formError.generic} />
    </>
  )
}

async function fetchLandSalePrices(
  ethereumAddress = ''
): Promise<requests.metaverse.LandSalePricesResponse | null> {
  const metaverseLandSale = makeCallableFunction('metaverse-landSalePrices')
  const response = await metaverseLandSale({
    address: ethereumAddress,
  })
  const responseData = response.data
  if (responseData && responseData.success) {
    return responseData
  }
  return null
}

async function fetchLandSaleContractAddress(
  ethereumAddress = ''
): Promise<requests.metaverse.LandSaleContractResponse | null> {
  const metaverseLandSale = makeCallableFunction('metaverse-landSale')
  const response = await metaverseLandSale({
    address: ethereumAddress,
  })
  const responseData = response.data
  if (responseData && responseData.success) {
    return responseData
  }
  return null
}

async function constructPurchaseTransaction(
  contractAddress: string,
  contractABI: requests.metaverse.LandSaleContractResponse['contractABI'],
  from: string,
  functionName: string,
  landType: LandType,
  quantity: number,
  landPrices: LandPrices
) {
  const landTypeValue: Record<LandType, number> = {
    '1x1': 1,
    '2x2': 2,
    '3x3': 3,
  }
  const landSaleContract = new ethers.Contract(contractAddress, contractABI)
  const txn = await landSaleContract.populateTransaction[functionName](
    quantity,
    landTypeValue[landType]
  )
  const value = BigInt(
    token.perform(
      token.Operation.Multiply,
      landPrices[landType],
      quantity.toString()
    )
  ).toString(16)
  return { from, value, ...txn }
}
