import * as React from 'react'
import { Screen, Heading } from '../../Screen'
import { MyWalletTabNavigator } from '../../../navigation/MyWalletTabNavigator'
import { useScreenMargins } from '../../../hooks/useScreenMargins'
import { View } from '../../View'

export function MyWalletScreen(): JSX.Element {
  const screenMargins = useScreenMargins().style

  const verticalMarginStyle = {
    marginTop: screenMargins.marginTop,
    marginBottom: screenMargins.marginBottom,
  }
  const horizontalMarginStyle = {
    marginLeft: screenMargins.marginLeft,
    marginRight: screenMargins.marginRight,
  }
  return (
    <Screen
      testID="screen-wallet"
      scrollable={false}
      noMargins
      style={verticalMarginStyle}
    >
      <View style={horizontalMarginStyle}>
        <Heading>My Multiverse Wallet</Heading>
      </View>
      <MyWalletTabNavigator />
    </Screen>
  )
}
