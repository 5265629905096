import * as React from 'react'
import { StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native'
// Note: The documentation at https://docs.ethers.io/v5/cookbook/react-native/
// recommends importing "@ethersproject/shims" first, but that doesn't seem to work,
// and doesn't seem necessary for the small amount of functionality we need.
import MetaMaskOnboarding from '@metamask/onboarding'
import Constants from 'expo-constants'
import { types } from '@mv/api'
import { logger } from '../../logger'
import { useDispatch, useSelector } from '../../state/store'
import { metaMaskActions } from '../../state/metaMaskSlice'
import { View, Text } from '..'
import { SubmitButton } from '../form'
import { LinkButton } from '../LinkButton'
import { Loading } from '../Loading'
import { useThemeColors } from '../../constants/colors'
import { MetaMask, MetaMaskIntegrationStage } from '../../state/metaMaskTypes'
import { logEvent, analytics } from '../../firebase/analytics'

const onboarding = new MetaMaskOnboarding()

type Props = {
  setCurrentEthBalance: (balance: string) => void
  style?: StyleProp<ViewStyle>
}

export function MetaMaskSetup({
  setCurrentEthBalance,
  style,
}: Props): JSX.Element {
  const dispatch = useDispatch()
  const { metaMask, metaMaskStage, connectedAccounts } = useSelector(
    (state) => ({
      metaMask: state.metaMask.metaMask,
      metaMaskStage: state.metaMask.metaMaskIntegrationStage,
      connectedAccounts: state.metaMask.connectedAccounts,
    })
  )
  const colors = useThemeColors()

  const [metaMaskButtonRender, setMetaMaskButtonRender] = React.useState(
    <Loading size="small" />
  )
  const [metaMaskStepText, setMetaMaskStepText] = React.useState(<></>)
  const [metaMaskStepTextBelow, setMetaMaskStepTextBelow] = React.useState(
    <></>
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { ethereum }: { ethereum?: MetaMask['ethereum'] } = (window as any) || {
    ethereum: undefined,
  }

  const setMetaMaskWithChainId = React.useCallback(
    (chainId: MetaMask['chainId']) => {
      if (ethereum && chainId) {
        dispatch(metaMaskActions.setMetaMask({ ethereum, chainId }))
      }
    },
    [dispatch, ethereum]
  )
  const setMetaMaskStep = React.useCallback(
    (step: MetaMaskIntegrationStage) => {
      dispatch(metaMaskActions.setIntegrationStage(step))
    },
    [dispatch]
  )
  const setConnectedAccounts = React.useCallback(
    (accounts: string[]) => {
      dispatch(
        metaMaskActions.setConnectedAccounts(
          accounts
            .map((lowerCaseAddress) => {
              try {
                return types.ethereum.getChecksumAddress(lowerCaseAddress)
              } catch (e) {
                logger.warn('Received invalid address from MetaMask')
                return ''
              }
            })
            .filter((x) => !!x)
        )
      )
    },
    [dispatch]
  )
  const resetMetaMaskState = React.useCallback(() => {
    dispatch(metaMaskActions.resetAll())
  }, [dispatch])

  React.useEffect(() => resetMetaMaskState, [resetMetaMaskState])

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled() && ethereum) {
      // Lookup and subscribe to changes to the chainId and accounts.
      ethereum
        .request({ method: 'eth_chainId' })
        .then(setMetaMaskWithChainId)
        .catch((e) => {
          logger.warn('Unable to identify metamask ethereum chain', e)
        })
      ethereum
        .request({ method: 'eth_accounts' })
        .then(setConnectedAccounts)
        .catch((e) => {
          logger.warn('Unable to retrieve metamask connected accounts', e)
        })
      ethereum.on('chainChanged', setMetaMaskWithChainId)
      ethereum.on('accountsChanged', setConnectedAccounts)

      // Remove listeners when unloading the component.
      return () => {
        logger.info('Unsubscribing from MetaMask')
        ethereum.removeListener('chainChanged', setMetaMaskWithChainId)
        ethereum.removeListener('accountsChanged', setConnectedAccounts)
      }
    }
    return () => {}
  }, [ethereum, setMetaMaskWithChainId, setConnectedAccounts])

  React.useEffect(() => {
    if (!metaMask) {
      // INSTALL METAMASK
      setMetaMaskStep('install')
      return
    }

    onboarding.stopOnboarding()

    if (metaMask?.chainId !== config.chainId) {
      // SWITCH METAMASK CHAIN
      setMetaMaskStep('switchChain')
    } else if (connectedAccounts.length === 0) {
      // CONNECT METAMASK ACCOUNTS TO MULTIVERSE
      setMetaMaskStep('connect')
    } else {
      // READY TO TRANSFER
      setMetaMaskStep('ready')
    }
  }, [setMetaMaskStep, metaMask, connectedAccounts])

  React.useEffect(() => {
    if (metaMask && connectedAccounts.length > 0) {
      const address = connectedAccounts[0]
      metaMask.ethereum
        .request({
          method: 'eth_getBalance',
          params: [address, 'latest'],
        })
        .then((b) => {
          const balance = b as string
          setCurrentEthBalance(
            types.token.toDecimalValue(
              BigInt(balance === '0x' ? '0x0' : balance),
              18
            )
          )
        })
        .catch((e) => {
          setCurrentEthBalance('')
          logger.error(e)
        })
    }
    setCurrentEthBalance('')
  }, [setCurrentEthBalance, metaMask, connectedAccounts])

  React.useEffect(() => {
    if (metaMaskStage === 'install') {
      setMetaMaskButtonRender(
        InstallMetaMaskButton({
          onBoardingFunction: onboarding.startOnboarding,
        })
      )
      setMetaMaskStepText(<></>)
      setMetaMaskStepTextBelow(
        <Text style={[styles.smallText, { color: colors.medium }]}>
          Requires Chrome, Firefox, or Edge browsers running on desktop
        </Text>
      )
    } else if (metaMaskStage === 'switchChain') {
      setMetaMaskButtonRender(SwitchChainButton({ metaMask }))
      setMetaMaskStepText(
        <Text style={styles.text}>
          Please switch to the {config.chainName} in MetaMask
        </Text>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    } else if (metaMaskStage === 'connect') {
      setMetaMaskButtonRender(ConnectAccountButton({ metaMask }))
      setMetaMaskStepText(
        <Text style={styles.text}>
          Select the MetaMask Wallet you wish to purchase Multiverse Metaverse
          Land NFTs with.
        </Text>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    } else if (metaMaskStage === 'ready') {
      setMetaMaskButtonRender(
        <View>
          <ConnectAccountButton metaMask={metaMask} alreadyConnected />
        </View>
      )
      setMetaMaskStepText(
        <View>
          <Text style={styles.text}>
            MetaMask setup and integration is now complete and you are ready to
            use MetaMask to purchase Multiverse Metaverse Land NFTs.
          </Text>
          <Text style={styles.text}>
            You can change with MetaMask account you wish to use by clicking the
            link below. Although it will allow you to select multiple accounts,
            MetaMask will only provide access to the first account you select.
          </Text>
        </View>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    }
  }, [
    metaMaskStage,
    metaMask,
    colors.medium,
    colors.textError,
    ethereum?.isMathWallet,
  ])

  return (
    <View style={style}>
      {metaMaskStage !== 'ready' && (
        <Text style={styles.text}>
          We&apos;ve integrated with MetaMask to make purchasing Multiverse
          Metaverse Land NFTs easy.
        </Text>
      )}
      {metaMaskStepText}
      <View style={styles.buttonRow}>{metaMaskButtonRender}</View>
      {metaMaskStepTextBelow}
    </View>
  )
}

const config = Constants.manifest.extra?.config

export function InstallMetaMaskButton({
  onBoardingFunction,
  disabled = undefined,
}: {
  onBoardingFunction: () => void
  disabled?: boolean
}): JSX.Element {
  if (disabled) {
    return (
      <SubmitButton
        label="MetaMask Already Installed"
        onPress={async () => false}
        disabled
      />
    )
  }
  return (
    <SubmitButton
      label="Install MetaMask"
      onPress={async () => {
        onBoardingFunction()
        logEvent(analytics(), 'wallet', { click: 'install metamask' })
        return false
      }}
    />
  )
}

export function SwitchChainButton({
  metaMask,
  disabled = undefined,
}: {
  metaMask?: MetaMask
  disabled?: boolean
}): JSX.Element {
  if (!metaMask) {
    return (
      <SubmitButton
        onPress={async () => false}
        label={`Switch to ${config.chainName} in MetaMask`}
        disabled
      />
    )
  }
  if (disabled) {
    return (
      <SubmitButton
        onPress={async () => false}
        label="MetaMask on Correct Ethereum Chain"
        disabled
      />
    )
  }
  return (
    <SubmitButton
      onPress={async () => {
        logEvent(analytics(), 'wallet', { click: 'switch metamask chain' })
        await metaMask.ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: config.chainId }],
          })
          .then(() => false) // handled by ethereum.on('accountsChanged') listener
          .catch((e: unknown) => {
            logger.warn(e)
          }) // e.g. if the user cancels
        return false
      }}
      label={`Switch to ${config.chainName} in MetaMask`}
    />
  )
}

export function ConnectAccountButton({
  metaMask,
  alreadyConnected = undefined,
  disabled = undefined,
}: {
  metaMask?: MetaMask
  alreadyConnected?: boolean
  disabled?: boolean
}): JSX.Element {
  if (!metaMask || disabled) {
    return (
      <SubmitButton
        onPress={async () => false}
        label="Connect MetaMask Wallets"
        disabled
      />
    )
  }
  if (alreadyConnected) {
    return (
      <LinkButton
        onPress={async () => {
          logEvent(analytics(), 'wallet', { click: 'connect metamask' })
          await metaMask.ethereum
            .request({
              method: 'wallet_requestPermissions',
              params: [{ eth_accounts: {} }],
            })
            .then(() => false) // handled by ethereum.on('accountsChanged') listener
            .catch((e: unknown) => logger.warn(e)) // e.g. if the user cancels
          return false
        }}
        label="Change MetaMask Account"
      />
    )
  }
  return (
    <SubmitButton
      onPress={async () => {
        logEvent(analytics(), 'wallet', { click: 'connect metamask' })
        await metaMask.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(() => false) // handled by ethereum.on('accountsChanged') listener
          .catch((e: unknown) => logger.warn(e)) // e.g. if the user cancels
        return false
      }}
      label="Connect MetaMask Wallets"
    />
  )
}

function MathWalletWarning({
  isMathWallet,
  errorStyle,
}: {
  isMathWallet: true | undefined
  errorStyle: StyleProp<TextStyle>
}): JSX.Element {
  if (isMathWallet) {
    return (
      <View style={styles.warningView}>
        <Text style={[styles.smallText, errorStyle]}>
          Having MathWallet and MetaMask installed at the same time may cause
          problems.
        </Text>
        <Text style={[styles.smallText, errorStyle]}>
          Please uninstall MathWallet.
        </Text>
      </View>
    )
  }
  return <></>
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  smallText: {
    fontSize: 14,
    paddingBottom: 8,
  },
  text: {
    paddingBottom: 8,
  },
  warningView: {
    marginTop: 4,
  },
})
