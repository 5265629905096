/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking'
import { getPathFromState, getStateFromPath } from '@react-navigation/native'
import Constants from 'expo-constants'

const { undefined: unused, ...landSaleLinkingConfig } = {
  undefined: '',
  [Constants.manifest.extra?.features?.landSale?.linking?.name]:
    Constants.manifest.extra?.features?.landSale?.linking?.value,
}

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          // StartScreen: {
          //   path: '',
          //   screens: {
          //     PlanetsShow: {
          //       path: '',
          //       screens: {
          //         PlanetTabOverview: {
          //           path: 'announcements',
          //           parse: {
          //             planetLocator: (): string => 'AI',
          //           },
          //         },
          //         PlanetTabChat: {
          //           path: 'topics',
          //           parse: {
          //             planetLocator: (): string => 'AI',
          //           },
          //           screens: {
          //             PlanetChat: {
          //               path: ':topicId',
          //               parse: {
          //                 planetLocator: (): string => 'AI',
          //                 topicId: (topicId?: string): string =>
          //                   !topicId || topicId === 'undefined'
          //                     ? 'main'
          //                     : topicId,
          //               },
          //             },
          //           },
          //         },
          //         PlanetTabForums: {
          //           path: 'forums',
          //           parse: {
          //             planetLocator: (): string => 'AI',
          //           },
          //         },
          //         PlanetTabPledge: {
          //           path: 'pledge',
          //           parse: {
          //             planetLocator: (): string => 'AI',
          //           },
          //         },
          //       },
          //     },
          //   },
          // },
          LoginScreen: {
            path: 'login',
          },
          JoinScreen: {
            path: 'join',
          },
          PlanetsScreen: {
            path: 'explore',
            screens: {
              PlanetsMenu: {
                path: '',
              },
              PlanetsCreate: {
                path: 'planet/create',
                exact: true,
              },
              PlanetsList: {
                path: 'explore/:type?',
                exact: true,
              },
              PlanetsShow: {
                path: '/planet/:planetLocator',
                exact: true,
                screens: {
                  PlanetTabOverview: {
                    path: '',
                  },
                  PlanetTabChat: {
                    path: 'topics',
                    screens: {
                      PlanetChat: {
                        path: ':topicId',
                        parse: {
                          topicId: (topicId?: string): string =>
                            !topicId || topicId === 'undefined'
                              ? 'main'
                              : topicId,
                        },
                      },
                    },
                  },
                  PlanetTabForums: {
                    path: 'forums',
                  },
                  PlanetTabPledge: {
                    path: 'pledge',
                  },
                },
              },
              // PlanetsShowPledge: {
              //   path: '/planet/:planetLocator/pledge',
              //   exact: true,
              // },
              // PlanetsShowForums: {
              //   path: '/planet/:planetLocator/brainstorm',
              //   exact: true,
              // },
              PlanetsEdit: {
                path: '/planet/:planetId/edit',
                exact: true,
              },
              PlanetsMyDrafts: {
                path: '/planetDrafts',
                exact: true,
              },
            },
          },
          MyWalletScreen: {
            path: 'wallet',
            screens: {
              MyWalletOverview: {
                path: '',
              },
              MyWalletHistory: {
                path: 'history',
              },
            },
          },
          ...landSaleLinkingConfig,
          AdminScreen: {
            path: 'admin',
          },
        },
      },
    },
  },
  getStateFromPath(
    path: Parameters<typeof getStateFromPath>[0],
    config: Parameters<typeof getStateFromPath>[1]
  ): ReturnType<typeof getStateFromPath> {
    // state override for root (load My Wallet for root) this effectively
    // redirects `/` to My Wallet showing the URL as `/wallet` (unless
    // getPathFromState maps My Wallet to `/` - then the URL will show as `/`)
    if (path === '/') {
      return {
        routes: [
          {
            name: 'Root',
            state: {
              routes: [
                {
                  name: 'MyWalletScreen',
                },
              ],
            },
          },
        ],
      }
    }
    return getStateFromPath(path, config)
  },
  getPathFromState(
    state: Parameters<typeof getPathFromState>[0],
    config: Parameters<typeof getPathFromState>[1]
  ): ReturnType<typeof getPathFromState> {
    // 2022-09-21 - Do not map Homeworld Main Chat to `/`
    // Remap example for future reference follows:
    // // homeworld main chat should map to root
    // if (
    //   state.routes[0].name === 'Root' &&
    //   state.routes[0].state?.routes[0].name === 'StartScreen' &&
    //   state.routes[0].state?.routes[0].state?.routes[0].name ===
    //     'PlanetsShow' &&
    //   state.routes[0].state?.routes[0].state?.routes[0].state?.routes[0]
    //     .name === 'PlanetTabChat' &&
    //   state.routes[0].state?.routes[0].state?.routes[0].state?.routes[0].state
    //     ?.routes[0].name === 'PlanetChat' &&
    //   (
    //     state.routes[0].state?.routes[0].state?.routes[0].state?.routes[0].state
    //       ?.routes[0].params as Record<string, string> | undefined
    //   )?.topicId === 'main'
    // ) {
    //   return '/'
    // }
    return getPathFromState(state, config)
  },
}
